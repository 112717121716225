import fetch from '~/services/httpRequest';
export const useDashboard = () => {
    const fetchDashboard = async () => {
        try {
            const result = await fetch.get(`/api/dashboard`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }
            return result.data.data;
        }
        catch (e) {
            console.error(e);
            return null;
        }
        finally { }
    };
    return {
        fetchDashboard,
    };
};
