export const classByTenantStatus = (status) => {
    let cssClass = '';
    switch (true) {
        case status === 'active':
            cssClass = 'success';
            break;
        case status === 'blocked':
            cssClass = 'error';
            break;
        case status === 'suspended' || status === 'warning':
            cssClass = 'warning';
            break;
    }
    return cssClass;
};
