import fetch from '~/services/httpRequest';
export const useTenant = () => {
    const fetchTenant = async (tenantId) => {
        try {
            const result = await fetch.get(`/api/tenant-profile/${tenantId}`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }
            return (result.data.data?.tenant ?? null);
        }
        catch (e) {
            console.error(e);
            return null;
        }
        finally { }
    };
    return {
        fetchTenant,
    };
};
