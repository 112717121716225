import fetch from '~/services/httpRequest';
export const useModule = () => {
    const fetchAllModules = async () => {
        try {
            const result = await fetch.get(`/api/modules/all`);
            if (!result.data.status) {
                throw new Error('Error server request');
            }
            return (result.data.data?.modules ?? null);
        }
        catch (e) {
            console.error(e);
            return null;
        }
        finally { }
    };
    return {
        fetchAllModules,
    };
};
