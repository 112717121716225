import { createRouter, createWebHistory } from 'vue-router';
import { isAuth } from "~/admin/central-admin/router/middleware/isAuth";
import Dashboard from "~/admin/central-admin/pages/dashboard.vue";
import Error404 from "~/admin/central-admin/pages/error/404.vue";
import SignIn from "~/admin/central-admin/pages/auth/sign-in.vue";
import Tenant from "~/admin/central-admin/pages/tenant/index.vue";
const routes = [
    {
        name: 'dashboard',
        path: '/',
        component: Dashboard,
        beforeEnter: isAuth, // todo this check permissions
        meta: {
            breadCrumb: [{ text: 'Главная' }],
            initialPosition: window.scrollY
        }
    },
    {
        name: 'tenant-profile',
        path: '/tenant-profile/:tenantId',
        component: Tenant,
        beforeEnter: isAuth, // todo this check permissions
        meta: {
            breadCrumb: [{ text: 'Арендатор' }],
            initialPosition: window.scrollY
        }
    },
    {
        name: 'sign-in',
        path: '/sign-in',
        component: SignIn,
        // beforeEnter: isAuth, // todo this check permissions
        meta: {
            breadCrumb: [{ text: 'Главная' }],
            initialPosition: window.scrollY
        }
    },
    {
        name: '404',
        path: '/:pathMatch(.*)*',
        component: Error404,
        meta: {
            breadCrumb: [{ text: 'Главная' }],
            initialPosition: window.scrollY
        }
    },
];
//@ts-ignore
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name === 'home' && from.name === 'comments') {
            to.meta.initialPosition = savedPosition.top;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ top: savedPosition.top, behavior: 'smooth' });
                }, 1000);
            });
        }
        return { top: 0 };
    },
});
export default router;
